<template>
  <section class="hero hero-partner">
    <div v-if="partner" class="container flex">
      <div v-if="partner.image">
        <img
          class="partner-image-big"
          :src="`${backURL}/${partner.image}`"
          :alt="partner.title"
        />
      </div>
      <div class="__content">
        <div class="hero-title--wrap display--flex">
          <h1 class="hero-title">{{ partner.title }}</h1>
        </div>
        <p v-if="partner.short_description" class="hero-text">
          {{ partner.short_description }}
        </p>
        <div class="hero-action btn-action display--flex">
          <button
            @click="openPartnerModal = true"
            type="button"
            class="btn btn-accent"
          >
            Оставить заявку
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="index-section">
    <div class="container">
      <div class="layout-partner display--grid">
        <div class="layout-main" v-if="partner">
          <div
            class="layout-item visible-xs"
            v-if="
              partner.city?.name ||
              (partner.job_formats && partner.job_formats.length)
            "
          >
            <div class="layout-item--title">Информация</div>
            <div class="layout-item---contacts">
              <div v-if="partner.city?.name" class="contacts-block">
                <div class="contacts-subtitle display--flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9998 5.00068C13.3254 5.00213 14.5961 5.52929 15.5333 6.4665C16.4706 7.40371 16.9979 8.67443 16.9993 9.99988C16.9993 13.5833 12.9982 17.6496 11.9998 18.6082C11.002 17.6502 7.00068 13.5839 7.00068 9.99988C7.00218 8.67447 7.52936 7.40378 8.46657 6.46657C9.40378 5.52936 10.6745 5.00218 11.9998 5.00068ZM11.9998 4C10.4092 4.00184 8.88415 4.63455 7.75935 5.75935C6.63455 6.88415 6.00183 8.40918 6 9.99988C6 14.5114 11.3269 19.3578 11.5536 19.5617L11.9998 19.9633L12.4461 19.5617C12.6729 19.3578 18 14.5116 18 9.99988C17.9981 8.40915 17.3654 6.88411 16.2405 5.75932C15.1157 4.63452 13.5906 4.00182 11.9998 4Z"
                    />
                    <path
                      d="M12.0002 8.76991C12.2443 8.76991 12.4829 8.84229 12.6858 8.9779C12.8888 9.11351 13.047 9.30626 13.1404 9.53178C13.2338 9.7573 13.2582 10.0054 13.2107 10.2449C13.163 10.4843 13.0455 10.7042 12.8728 10.8767C12.7002 11.0494 12.4804 11.1669 12.2409 11.2146C12.0016 11.2621 11.7534 11.2377 11.5279 11.1443C11.3024 11.0509 11.1096 10.8927 10.974 10.6897C10.8384 10.4868 10.766 10.2482 10.766 10.0041C10.7664 9.67687 10.8965 9.36317 11.1279 9.1318C11.3593 8.90042 11.6729 8.77028 12.0002 8.76991ZM12.0002 7.83594C11.5713 7.83594 11.1522 7.9631 10.7956 8.20133C10.4391 8.43957 10.1612 8.77819 9.99707 9.17437C9.83297 9.57054 9.79003 10.0065 9.87369 10.4271C9.95735 10.8477 10.1638 11.234 10.4671 11.5372C10.7703 11.8404 11.1566 12.0469 11.5772 12.1306C11.9978 12.2143 12.4337 12.1713 12.8299 12.0072C13.2261 11.8431 13.5647 11.5652 13.8029 11.2086C14.0412 10.8521 14.1683 10.4329 14.1683 10.0041C14.1683 9.42905 13.9399 8.87758 13.5333 8.47097C13.1267 8.06437 12.5752 7.83594 12.0002 7.83594Z"
                    />
                  </svg>
                  Город
                </div>
                <p>
                  {{ partner.city?.name?.name }}
                </p>
              </div>
              <div
                v-if="partner.job_formats && partner.job_formats.length"
                class="contacts-block"
              >
                <div class="contacts-subtitle display--flex">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6V17.2H19V6H5ZM17.95 7.05V8.26247L12 11.698L6.05 8.26247V7.05H17.95ZM6.05 16.15V9.47487L12 12.9105L17.95 9.47487V16.15H6.05Z"
                    />
                  </svg>
                  Форматы
                </div>
                <p v-for="format of partner.job_formats" :key="format">
                  {{ format }}
                </p>
              </div>
              <div class="contacts-block text-center">
                <button
                  @click="openPartnerModal = true"
                  type="button"
                  class="btn btn-accent"
                >
                  Оставить заявку
                </button>
              </div>
            </div>
          </div>

          <div class="layout-item">
            <div class="layout-item--title">Услуги</div>
            <div class="layout-item--services" v-if="partner">
              <div
                v-for="service in partner.services"
                :key="service"
                class="partner-service display--flex"
              >
                <div class="partner-service--title">
                  {{ service }}
                </div>
              </div>
            </div>
            <div class="btn-action display--flex">
              <button
                @click="openConsultModal = true"
                type="button"
                class="btn btn-o-accent"
              >
                Проконсультироваться
              </button>
            </div>
          </div>
          <div class="layout-item" v-if="!isPartner && partner.description">
            <div class="layout-item--title">История</div>
            <div class="layout-item---text">
              <div v-html="partner.description"></div>
            </div>
          </div>
          <div class="layout-item" v-if="!isPartner && partner.education">
            <div class="layout-item--title">Образование</div>
            <div class="layout-item---text">
              <div v-html="partner.education"></div>
            </div>
          </div>
          <div v-if="partner.reviews && partner.reviews.length">
            <reviews-block :reviews="partner.reviews" />
          </div>
        </div>
        <aside
          class="layout-aside"
          v-if="partner && (partner.city?.name || partner.job_formats.length)"
        >
          <div class="layout-aside-block">
            <div class="layout-item hidden-xs">
              <div class="layout-item--title">Информация</div>
              <div class="layout-item---contacts">
                <div v-if="partner.city?.name" class="contacts-block">
                  <div class="contacts-subtitle display--flex">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9998 5.00068C13.3254 5.00213 14.5961 5.52929 15.5333 6.4665C16.4706 7.40371 16.9979 8.67443 16.9993 9.99988C16.9993 13.5833 12.9982 17.6496 11.9998 18.6082C11.002 17.6502 7.00068 13.5839 7.00068 9.99988C7.00218 8.67447 7.52936 7.40378 8.46657 6.46657C9.40378 5.52936 10.6745 5.00218 11.9998 5.00068ZM11.9998 4C10.4092 4.00184 8.88415 4.63455 7.75935 5.75935C6.63455 6.88415 6.00183 8.40918 6 9.99988C6 14.5114 11.3269 19.3578 11.5536 19.5617L11.9998 19.9633L12.4461 19.5617C12.6729 19.3578 18 14.5116 18 9.99988C17.9981 8.40915 17.3654 6.88411 16.2405 5.75932C15.1157 4.63452 13.5906 4.00182 11.9998 4Z"
                      />
                      <path
                        d="M12.0002 8.76991C12.2443 8.76991 12.4829 8.84229 12.6858 8.9779C12.8888 9.11351 13.047 9.30626 13.1404 9.53178C13.2338 9.7573 13.2582 10.0054 13.2107 10.2449C13.163 10.4843 13.0455 10.7042 12.8728 10.8767C12.7002 11.0494 12.4804 11.1669 12.2409 11.2146C12.0016 11.2621 11.7534 11.2377 11.5279 11.1443C11.3024 11.0509 11.1096 10.8927 10.974 10.6897C10.8384 10.4868 10.766 10.2482 10.766 10.0041C10.7664 9.67687 10.8965 9.36317 11.1279 9.1318C11.3593 8.90042 11.6729 8.77028 12.0002 8.76991ZM12.0002 7.83594C11.5713 7.83594 11.1522 7.9631 10.7956 8.20133C10.4391 8.43957 10.1612 8.77819 9.99707 9.17437C9.83297 9.57054 9.79003 10.0065 9.87369 10.4271C9.95735 10.8477 10.1638 11.234 10.4671 11.5372C10.7703 11.8404 11.1566 12.0469 11.5772 12.1306C11.9978 12.2143 12.4337 12.1713 12.8299 12.0072C13.2261 11.8431 13.5647 11.5652 13.8029 11.2086C14.0412 10.8521 14.1683 10.4329 14.1683 10.0041C14.1683 9.42905 13.9399 8.87758 13.5333 8.47097C13.1267 8.06437 12.5752 7.83594 12.0002 7.83594Z"
                      />
                    </svg>
                    Город
                  </div>
                  <p>
                    {{ partner.city?.name }}
                  </p>
                </div>
                <div
                  v-if="partner.job_formats && partner.job_formats.length"
                  class="contacts-block"
                >
                  <div class="contacts-subtitle display--flex">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 6V17.2H19V6H5ZM17.95 7.05V8.26247L12 11.698L6.05 8.26247V7.05H17.95ZM6.05 16.15V9.47487L12 12.9105L17.95 9.47487V16.15H6.05Z"
                      />
                    </svg>
                    Форматы
                  </div>
                  <p v-for="format of partner.job_formats" :key="format">
                    {{ format }}
                  </p>
                </div>
                <div class="contacts-block text-center">
                  <button
                    @click="openPartnerModal = true"
                    type="button"
                    class="btn btn-accent"
                  >
                    Оставить заявку
                  </button>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </section>
  <pop-up-form
    title="Получить консультацию"
    v-if="openConsultModal"
    type="ConsultPartner"
    @closeModal="openConsultModal = false"
  ></pop-up-form>
  <pop-up-form
    title="Оставить заявку"
    type="PartnerModal"
    :isPartner="isPartner"
    :partnerId="route.params.id"
    v-if="openPartnerModal"
    @closeModal="openPartnerModal = false"
  ></pop-up-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ReviewsBlock from "../../components/reviews-block/ReviewsBlock.vue";
export default {
  name: "expert-page",
  components: {
    PopUpForm,
    ReviewsBlock,
  },
  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter();
    const openConsultModal = ref(false),
      openPartnerModal = ref(false),
      isPartner = computed(() => route.path.startsWith("/partner")),
      fullTeachers = ref(false);
    const partner = computed(() => store.state.partners.partner),
      firstThreeTeacher = computed(() =>
        partner.value.teachers ? partner.value.teachers.data.slice(0, 3) : null
      );
    const backURL = process.env.VUE_APP_LK_BACK;

    const getExpert = () => {
      store.dispatch("partners/getExpert", route.params.id).then(() => {
        if (!partner.value) {
          router.push("/404");
        }
      });
    };
    onBeforeMount(() => {
      getExpert();
    });

    watch(
      () => route.params.id,
      () => {
        if (route.params.id) getExpert();
      }
    );

    return {
      openConsultModal,
      isPartner,
      partner,
      fullTeachers,
      firstThreeTeacher,
      openPartnerModal,
      backURL,

      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/partners";

.hero-action {
  margin-top: 24px;
}
</style>
<style lang="scss">
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.2s ease-out;
}

.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>